@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
  background-image: url('assets/images/bg_body.jpg');
}

.td-leafnumber {
  height: 240.75px;
}

.text-default {
  font-size: 13px;
  font-family: 'Roboto Condensed';
  line-height: 15.23px;
}

.phu-tinh {
  font-size: 11px;
  word-spacing: -1px;
  font-family: 'Roboto Condensed';
  line-height: 15.23px;
}

.chinh-tinh {
  font-size: 15px;
  font-weight: 800;
  font-family: 'Roboto Condensed';
  line-height: 17px;
}

.title-leaf-number-center {
  font-family: 'Roboto Condensed';
  font-size: 23px;
  font-weight: 700;
  line-height: 24.61px;
  color: #FAFE58;
  background-color: #B3B3B3;
  text-transform: uppercase;
  z-index: 99999999;
}

.sub-title-leaf-number-center {
  margin-top: 4px;
  font-family: 'Roboto Condensed';
  font-size: 16;
  font-weight: 700;
  line-height: 18.75px;
  color: #fff;
  background-color: #e60000;
}

.label {
  color: #03036E;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Roboto Condensed';
  line-height: 21px;
  z-index: 10;
  /* -webkit-text-stroke: 0.7px #000; */
}

.value {
  /* color: #011b51; */
  color: #262626;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Roboto Condensed';
  line-height: 21px;
  z-index: 10;

}

.img-leaf-number-center {
  position: absolute;
  width: 340px;
  z-index: 1;
  top: 85px;
}

.canh-dao {
  position: absolute;
  width: 300px;
  height: 400px;
  top: 85px;
  z-index: 0;
  right: 0;
  /* opacity: 0.6; */
}

.img-leaf-number-text {
  position: absolute;
  z-index: 2;
  bottom: 110px;
  right: 20px;
}

.img-leaf-number-dau {
  position: absolute;
  z-index: 0;
  bottom: 95px;
  right: 20px;
}

.border-center {
  border-width: 1px;
  border-color: #000;
  border-style: solid;
  position: relative;
  height: 100%;
}

.tuan-or-triet {
  font-size: 12px;
  /* line-height: 14px; */
  width: 50px;
  background: #000;
  z-index: 999;
  font-weight: bold;
}

.tuan-and-triet {
  font-size: 12px;
  /* line-height: 14px; */
  width: 80px;
  background: #000;
  z-index: 999;
  font-weight: bold;
}

.radio-custom .mb-3 {
  margin-bottom: 0px !important;
}